import loadable from "@loadable/component";
import QuantumCategories from "pages/quantumCategories";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import ServiceInformation from "pages/ServiceInformation";
import ServiceInsights from "pages/ServiceInsights";
import ViewDSCApply from "pages/dsc-apply-view";
import WhatsappRequests from "pages/whatsapp-requests";
import AtomProLimitRequests from "pages/AtomPro/atomProLimitRequests";

const Login = loadable(() => import("pages/login"));
const ResetPassword = loadable(() => import("pages/reset-password"));
const Services = loadable(() => import("pages/services"));
const AddService = loadable(() => import("pages/services/add-service"));
const Dashboard = loadable(() => import("pages/dashboard"));
const Calendar = loadable(() => import("pages/calendar"));
const Layout = loadable(() => import("layout/primarylayout"));
const Roles = loadable(() => {
  return import("pages/roles-permissions");
});
const EditPermissions = loadable(() => {
  return import("pages/roles-permissions/edit-permissions");
});
const Categories = loadable(() => import("pages/categories"));
const StageToProd = loadable(() => import("pages/stageToProd"))
const QtmStageToProd = loadable(() => import("pages/qtmStageToProd"));
const QuantumTemplateFeed = loadable(() => import("pages/quantum-template-feed/create-quantum-template"))
const QuantumTemplates = loadable(() => import("pages/quantum-template-feed/index"))
const Labels = loadable(() => import("pages/labels"));
const DocumentLabels = loadable(() => import("pages/documentLabels"))
const Organizations = loadable(() => import("pages/organizations"));
const ViewOrganization = loadable(
  () => import("pages/organizations/view-organization")
);
const ApplyDSC = loadable(() => import("pages/dsc-apply"));

const Forms = loadable(() => import("pages/forms"));
const Esign = loadable(() => import("pages/forms/Esign"));
const FormTemplates = loadable(() => import("pages/forms/FormTemplates"));
const FormValidations = loadable(() => import("pages/forms/FormValidations"));
const FormBuilder = loadable(() => import("pages/forms/FormBuilder"));
const AccessForm = loadable(() => import("pages/forms/AccessForm"));
const Prism = loadable(() => import("pages/Prism"));
const AllOrganizationUsers = loadable(() => import("pages/AllUsers"))
const QuantumRequests = loadable(() => import("pages/quantumRequests"));
const AtomProMachines = loadable(() => import("pages/atomProMachines"));


function RoutesContainer() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="dashboard" />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="organizations">
            <Route index element={<Organizations />} />
            <Route path=":id" element={<ViewOrganization />} />
          </Route>
          <Route path="calendar" element={<Calendar />} />
          <Route path="services">
            <Route index element={<Services />} />
            <Route path="add" element={<AddService />} />
            <Route path="overview" element={<ServiceInformation />} />
            <Route path="insights" element={<ServiceInsights />} />
          </Route>
          <Route path="forms" element={<Forms />}>
            <Route index element={<FormTemplates />} />
            <Route path="form-validations" element={<FormValidations />} />
          </Route>
          <Route path="categories" element={<Categories />} />
          <Route path="dsc-apply">
            <Route index element={<ApplyDSC />} />
            <Route path=":id" element={<ViewDSCApply />} />
          </Route>
          <Route path="labels" element={<Labels />} />
          <Route path="roles-permissions">
            <Route index element={<Roles />} />
            <Route path=":roleId" element={<EditPermissions />} />
          </Route>
          <Route path="stageToProd" element={<StageToProd />}></Route>
          <Route path="qtmStageToProd" element={<QtmStageToProd />}></Route>
          <Route path="documentLabels" element={<DocumentLabels />} />
          <Route path="quantumCategories" element={<QuantumCategories />} />
          <Route path="quantumTemplateFeed">
            <Route index element={<QuantumTemplates />} />
            <Route path="create-quantum-template" element={<QuantumTemplateFeed />} />
          </Route>
          <Route path='prism' >
            <Route index element={<Prism />} />
          </Route>
          <Route path='quantumRequests' >
            <Route index element={<QuantumRequests />} />
          </Route>
          <Route path='atomProMachines' >
            <Route index element={<AtomProMachines />} />
          </Route>
          <Route path="atomProLimitRequests" >
            <Route index element={<AtomProLimitRequests />} />
          </Route>
          <Route path='whatsappRequests' >
            <Route index element={<WhatsappRequests />} />
          </Route>
          <Route path='allUsers' >
            <Route index element={<AllOrganizationUsers />} />
          </Route>
        </Route>
        <Route path="/forms/builder/:formId" element={<FormBuilder />} />
        <Route path="/forms/access/:formId" element={<AccessForm />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
    </Router>
  );
}

export default RoutesContainer;
