import { http } from "api/http";

const getOrganizations = ({ queryKey }) => {
  return http.get(`/admin/organizations`, { params: { ...queryKey[1] } });
};

const getOrganization = ({ queryKey }) => {
  return http.get(`/admin/organizations/${queryKey[1]}`);
};

const getOrganizationUsers = ({ queryKey }) => {
  let orgId = queryKey[1].id;
  return http.get(`/admin/organizationUsers/${orgId}`, {
    params: { ...queryKey[1] },
  });
};

const updateOrganization = ({ id, data }: { id: number; data: any }) => {
  return http.put(`/admin/organizations/${id}`, data);
};

const getOrgNotificationConfig = ({ queryKey }) => {
  return http.get(`/admin/notificationConfig/${queryKey[1]}`);
};

const updateWhatsAppConfig = ({ id, data }) => {
  return http.put(`/admin/notificationConfig/${id}`, data);
};

const getAllOrganizationUsers = ({ queryKey }) => {
  return http.get(`/admin/allOrganizationUsers`, {
    params: { ...queryKey[1] },
  });
};

const getQuantumConfig = ({ queryKey }) => {
  return http.get(`admin/getQuantumConfig/${queryKey[1]}`);
};

const updateQuantumConfig = ({ id, data }) => {
  return http.put(`/admin/quantumConfig/${id}`, data);
};

const getQuantumRequests = ({ queryKey }) => {
  return http.get(`admin/quantumRequests`, { params: { ...queryKey[1] } });
};

const getAtomProMachines = ({ queryKey }) => {
  return http.get(`admin/atomProMachines`, { params: { ...queryKey[1] } });
};

const deleteAtomProServerMachines = (data) => {
  console.log(data);
  return http.put(`admin/atomProMachines`, data);
};

const upadteInqueueStatus = () => {
  return http.put(`admin/updateInqueueStatus`);
};

const deleteServerRowsAndChangeStatus = () => {
  return http.put(`admin/deleteServerRowsAndChangeStatus`);
};

const updateServerStatus = () => {
  return http.put(`admin/updateServerStatus`);
};

const getAutomationConfig = ({ queryKey }) => {
  return http.get(`admin/getAutomationConfig/${queryKey[1]}`);
};

const updateAutomationConfig = ({ id, data }) => {
  return http.put(`/admin/automationConfig/${id}`, data);
};

const getAutomationScheduling = ({ queryKey }) => {
  return http.get(`/admin/automationScheduling/${queryKey[1]}`);
};

const updateGstrEnableStatus = ({ id }) => {
  return http.put(`/admin/gstrEnableStatus/${id}`);
};

const updateGstrDisableStatus = ({ id }) => {
  return http.put(`/admin/gstrDisableStatus/${id}`);
};

const updateIncometaxEnableStatus = ({ id }) => {
  return http.put(`/admin/incometaxEnableStatus/${id}`);
};

const updateIncometaxDisableStatus = ({ id }) => {
  return http.put(`/admin/incometaxDisableStatus/${id}`);
};

const organizationGstrScheduling = (data) => {
  return http.put(`/admin/scheduling`, data);
};

const getWhatsappRequests = ({ queryKey }) => {
  return http.get(`/whatsapp/requests`, { params: { ...queryKey[1] } });
};

const getAtomProLimitRequests = () => {
  return http.get(`admin/atomProLimitRequests`);
};

const responseForAtomProLimit = ({ id, data }) => {
  return http.put(`admin/atomProLimitRequests/${id}`, data);
};

export {
  getOrganizations,
  getOrganization,
  updateOrganization,
  getOrgNotificationConfig,
  updateWhatsAppConfig,
  getOrganizationUsers,
  getAllOrganizationUsers,
  getQuantumConfig,
  updateQuantumConfig,
  getQuantumRequests,
  getAutomationConfig,
  updateAutomationConfig,
  getWhatsappRequests,
  getAtomProLimitRequests,
  responseForAtomProLimit,
  getAutomationScheduling,
  organizationGstrScheduling,
  updateGstrDisableStatus,
  updateGstrEnableStatus,
  updateIncometaxEnableStatus,
  updateIncometaxDisableStatus,
  getAtomProMachines,
  deleteAtomProServerMachines,
  upadteInqueueStatus,
  updateServerStatus,
  deleteServerRowsAndChangeStatus,
};
